@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary: #4b9cd3;
  --swiper-navigation-color: #292929;
  --primary-font: #1f232e;
  --secondary-font: #585858;
}
.swiper-button-prev {
  left: -0.1rem !important;
}

.swiper-button-next {
  right: -0.1rem !important;
}
.swiper-slide {
  width: 400px !important;
  margin: 0;
}
@media (width < 700px) {
  .swiper-slide {
    width: 100px !important;
    margin-left: 2px;
  }
}
*::after ::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Abril Fatface", serif;
  font-weight: 400;
  font-style: normal;
  color: var(--primary-font);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
